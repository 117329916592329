@import "../core/functions";

.cms-no-route {
  .page-main {
    padding-top: 5rem;
  }
}

.cms-content, .cms-page-view .column.main {
  h2, h3, h4, h5, h6 {
    font-family: var(--pa-font-base--500);
    margin-bottom: 2rem;
    padding-top: 1rem;
  }
  p, ul, ol {
    margin-bottom: 2rem;
    a {
      @include underline-link-hover--reverse();
      font-family: var(--pa-font-base--500);
      &:hover {
        color: var(--pa-color-primary);
      }
    }
  }
  li {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
  dt {
    font-family: var(--pa-font-base--500);
  }
  blockquote, q {
    font-family: var(--pa-font-base);
    font-style: italic;
    font-size: 1.8rem;
    &:before {
      content: '\201C';
    }
    &:after {
      content: '\201D';
    }
  }
  img {
    margin-bottom: 1rem;
  }
}

@media screen and (min-width: 575px){
  .cms-home .block-static-block .item:first-child img {
    top: 0px;
    max-width: 310px;
    min-width: 240px;
    width: 17vw;
    right: 50px;
  }
}

.cms-index-index.forening {
  .page-wrapper {
      .page {
        &.messages {
          display: block;
        }
      }
    }
}